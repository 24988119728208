import React from "react";
import { connect } from "react-redux";
import { setInternalServerError } from "../../store/actions/settings";
import Alert from "react-bootstrap/Alert";
import {useTranslation} from "react-i18next";

const InternalServerError = ({
  setInternalServerError,
  internalServerError,
}) => {
  const {t, i18n} = useTranslation();
  return (
      <div>
        {internalServerError && (
            <Alert variant="danger" className="alert-container">
              <button
                  type="button"
                  className="close"
                  onClick={() => setInternalServerError(false)}
              >
          <span aria-hidden="true">
            <i className="fas fa-times"/>
          </span>
                <span className="sr-only">Close</span>
              </button>
              <Alert.Heading>{t("internal_server_error")}</Alert.Heading>
              <p>{t("internal_server_error_description")}</p>
            </Alert>
        )}
      </div>
  );
};

const mapStateToProps = (state) => ({
  internalServerError: state.settings.internalServerError,
});

export default connect(mapStateToProps, { setInternalServerError })(
  InternalServerError
);
