import {SET_USER_DETAILS, SET_AUTH_TOKEN, SET_CURRENT_PROJECT} from "../actionTypes";

// import isEmpty from "../../utils/isEmpty";

const authInitialState = {
  isAuthenticated: true,
  token: null,
  user: {},
  currentProject : {}
};

export default function (state = authInitialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        isAuthenticated: true, //!isEmpty(action.payload),
        user: action.payload,
      };
    case SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.payload
      }
    default:
      return state;
  }
}
