import React from "react";
import Modal from "react-bootstrap/Modal";


const SuspenseLoader = () => (
    <div>
        <Modal aria-labelledby="contained-modal-title-vcenter" size='sm' show={true} centered>
            <div className='load-wrap mx-auto'>
                <div className='loader5' />
                <p>Loading...</p>
            </div>
        </Modal>
    </div>
);


export default SuspenseLoader;
