import {combineReducers} from "redux";
import authReducer from "./auth";
import settingsReducer from './settings';

export default combineReducers({
  //settings: settingsReducer,
  auth: authReducer,
  settings: settingsReducer,
  //error: errorReducer,
});
