import React from "react";
import {connect} from "react-redux";
import {setNetworkError} from "../../store/actions/settings";
import Alert from "react-bootstrap/Alert";
import {useTranslation} from "react-i18next";

const NetworkError = ({ networkError, setNetworkError }) => {
  const {t, i18n} = useTranslation();
  return (
      <div>
        {networkError && (
            <Alert variant="danger" className="alert-container">
              <button
                  type="button"
                  className="close"
                  onClick={() => setNetworkError(false)}
              >
          <span aria-hidden="true">
            <i className="fas fa-times"/>
          </span>
                <span className="sr-only">Close</span>
              </button>
              <Alert.Heading>{t("network_error")}</Alert.Heading>
              <p>{t("network_error_description")}</p>
            </Alert>
        )}
      </div>
  );

}

const mapStateToProps = (state) => ({
  networkError: state.settings.networkError,
});

export default connect(mapStateToProps, { setNetworkError })(NetworkError);
