import React, {Component} from "react";
import Navbar from "react-bootstrap/Navbar";
import NavLogo from "./NavLogo";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {setLanguage} from "../store/actions/settings";

class NavbarDiv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: '',
            isButtonClicked: true,
            navItems: props.navItems,
            navDropDown: props.navDropDown,
            navLogo: props.navLogo,
        };
    }

    componentDidMount() {
        this.setState({
            lang: this.props.lang
        })
        this.props.setLanguage(this.props.lang);
        this.props.i18n.changeLanguage(this.props.lang);
    }

    switchLanguage = () => {
        if (this.props.lang === "bn") {
            this.props.i18n.changeLanguage("en")
            this.props.setLanguage("en")
        }
        else {
            this.props.i18n.changeLanguage("bn");
            this.props.setLanguage("bn");
        }
    }

    render() {
        const {t, i18n} = this.props;
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">
                    <NavLogo
                        href={this.state.navLogo.href}
                        imgSrc={this.state.navLogo.src}
                        altText={this.state.navLogo.altText}
                        width={this.state.navLogo.width}
                        height={this.state.navLogo.height}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = state => ({
    lang: state.settings.language
})

export default withTranslation('translation')(connect(mapStateToProps, {setLanguage})(NavbarDiv));
