import React, { Component } from "react";

class Logo extends Component {
    state = {

    };
    render() {
        return (
            <a className="text-dark" href={this.props.href}>
                <img
                    className="mb-4 m-auto"
                    src={this.props.imgSrc}
                    alt={this.props.altText}
                    width={this.props.width}
                    height={this.props.height}
                />
            </a>
        );
    }
}

export default Logo;
