import React from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "./App.css";
import configureStore from "../src/store/configureStore";
import AppRouter from "./router/AppRouter";
// import "./styles/style.scss";

const { store, persistor } = configureStore();

function App() {
  return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
        </PersistGate>
      </Provider>
  );
}

export default App;
