import logo from "../assets/Group-178.png";

export const BaseURL = process.env.CREATE_REACT_APP_API === undefined ? "https://dev.inspiringbangladesh.org/api/" : process.env.CREATE_REACT_APP_API;

export const navLogo = {
    href: "https://www.inspiring-bangladesh.org",
    src: logo,
    altText: "InspiringBD logo",
    height: '40px',
};

export const navItems = [
    // { href: "#", text: "Memes" },
    // { href: "#", text_en: "Contact", text_bn: "যোগাযোগ" },
    // { href: "#", text_en: "About Us", text_bn: "আমাদের কথা" },
];

export const navDropDown = {
    text: "Account",
    items: [
        { text: "drop1", href: "#" },
        { text: "drop2", href: "#" },
        { text: "drop3", href: "#" },
    ],
    isOpen: false,
};

export const projectIconClasses = {
    'Total Collected': 'fas fa-hand-holding-usd',
    'Balance': 'fas fa-coins',
    'Contributions': 'fas fa-chart-line',
    'Total Spent': 'fas fa-arrow-circle-down',
}