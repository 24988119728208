import React, {lazy, Suspense} from "react";
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";
import "../styles/styles.scss";
import NetworkError from "../components/snippets/NetworkError";
import InternalServerError from "../components/snippets/InternalServerError";
import NavbarDiv from "../components/NavbarDiv";
import {navDropDown, navItems, navLogo} from "../config/utility";
import SuspenseLoader from "../components/snippets/SuspenseLoader";

const HomePage = lazy(() => import("../components/HomePage"));
const CollectionDetails = lazy( ()=>import("../components/CollectionDetails"));
const ExpenseDetails = lazy( ()=>import("../components/ExpenseDetails"));
const ProjectLanding = lazy( ()=>import("../components/ProjectLanding"));
const ProjectDetails = lazy( ()=>import("../components/ProjectDetails"));

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
      <NavbarDiv
          navLogo={navLogo}
          navItems={navItems}
          navDropDown={navDropDown}
      />
      <Suspense fallback={<SuspenseLoader />}>
          <Switch>
              <Route exact={true} path="/" component={HomePage} />
             {/* <Route exact={true} path="/login" component={LoginForm} />*/}
              <Route exact={true} path="/collection_details" component={CollectionDetails} />
              <Route exact={true} path="/expense_details" component={ExpenseDetails} />
              <Route exact={true} path="/projects" component={ProjectLanding} />
              <Route exact={true} path="/projects/:projectId" component={ProjectDetails} />
        </Switch>
      </Suspense>
      <NetworkError />
      <InternalServerError />
  </Router>
);

export default AppRouter;
