import {
    SET_NETWORK_ERRORS,
    SET_UNAUTHORIZED_ERRORS,
    SET_INTERNAL_SERVER_ERRORS, SET_LANGUAGE
} from "../actionTypes";

const initialErrorState = {
    networkError: false,
    unauthorizedError: false,
    internalServerError: false,
    language: 'bn'
};

export default (state = initialErrorState, action) => {
    switch (action.type) {
        case SET_NETWORK_ERRORS:
            return {
                ...state,
                networkError: action.payload,
                internalServerError: false
            };
        case SET_UNAUTHORIZED_ERRORS:
            return {
                ...state,
                unauthorizedError: action.payload
            };
        case SET_INTERNAL_SERVER_ERRORS:
            return {
                ...state,
                internalServerError: action.payload,
                networkError: false
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            }
        default:
            return state;
    }
};
