import {SET_INTERNAL_SERVER_ERRORS, SET_NETWORK_ERRORS, SET_UNAUTHORIZED_ERRORS, SET_LANGUAGE} from "../actionTypes";


export const setNetworkError = (flag) => {
    return {
        type: SET_NETWORK_ERRORS,
        payload: flag
    };
};

export const setUnauthorizedError = (flag) => {
    return {
        type: SET_UNAUTHORIZED_ERRORS,
        payload: flag
    };
};

export const setInternalServerError = (flag) => {
    return {
        type: SET_INTERNAL_SERVER_ERRORS,
        payload: flag
    };
};

export const setLanguage = (lang) => {
    return {
        type: SET_LANGUAGE,
        payload: lang
    }
}