import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import bangla_translation from "./languages/bangla/translation";
import english_translation from "./languages/english/translation";


// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: english_translation
    },
    bn: {
        translation: bangla_translation
    }

};



i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'bn',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;